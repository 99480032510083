
import {defineComponent, ref} from "vue";
import {key} from "../../store/index";
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useLoading} from "vue-loading-overlay";
import * as toastr from "toastr";

export default defineComponent({
  name: 'RecoverPassword',
  components: {
    topNav,
    bottomNav,
  },
  setup() {
    const resetRef = ref(null);
    const email = ref("");
    const store = useStore(key);
    const router = useRouter();
    const $loader:any = useLoading();

    const sendResetLink = async () => {
    const loader =  $loader.show({container: resetRef.value});
    try {
      let response = await store.dispatch('auth/sendPasswordResetLink', {email: email.value});
      if (response.status) {
        await toastr.success(response.message);
        return router.push({name: 'Login'})
      }
    }catch (e) {
      await toastr.error(e.message);
    }finally {
      loader.hide();
    }

    }
    return {
      email,
      resetRef,
      sendResetLink
    }
  }
})
