import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "breadcrumb-area" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "breadcrumb-contents" }
const _hoisted_6 = { class: "page-title" }
const _hoisted_7 = { class: "breadcrumb" }
const _hoisted_8 = { class: "active" }
const _hoisted_9 = { href: "#" }
const _hoisted_10 = { class: "pass_recover_area section--padding" }
const _hoisted_11 = { class: "container" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  class: "col-lg-6 offset-lg-3",
  ref: "resetRef"
}
const _hoisted_14 = { class: "cardify recover_pass" }
const _hoisted_15 = { class: "login--header" }
const _hoisted_16 = { class: "login--form" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { for: "email_ad" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = {
  class: "btn btn--md register_btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_nav = _resolveComponent("top-nav")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bottom_nav = _resolveComponent("bottom-nav")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_top_nav),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('recoverPasswordPage.title')), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "/" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('homePage.nav.home')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_8, [
                    _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t('recoverPasswordPage.title')), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendResetLink && _ctx.sendResetLink(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('recoverPasswordPage.welcome')), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('recoverPasswordPage.email')), 1),
                    _withDirectives(_createElementVNode("input", {
                      id: "email_ad",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                      type: "email",
                      class: "text_field",
                      placeholder: _ctx.$t('recoverPasswordPage.emailHint'),
                      required: ""
                    }, null, 8, _hoisted_19), [
                      [_vModelText, _ctx.email]
                    ])
                  ]),
                  _createElementVNode("button", _hoisted_20, _toDisplayString(_ctx.$t('recoverPasswordPage.buttonText')), 1)
                ])
              ])
            ], 32)
          ], 512)
        ])
      ])
    ]),
    _createVNode(_component_bottom_nav)
  ], 64))
}